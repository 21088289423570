body,
html,
nai-root {
  font-family: var(--font-primary), sans-serif;
  font-size: var(--html-font-size);
  line-height: var(--line-height-base);
  color: var(--color-text);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span,
div,
a {
  color: var(--color-text);
}
.font-grey {
  color: var(--color-neutral-variant-50);
}

.font-error {
  color: var(--color-error);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-medium);
}
p {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

h1 {
  font-size: var(--font-size-xxlarge);
  line-height: var(--line-height-xxlarge);
  font-weight: 600;
}

button {
  font-family: var(--font-primary);
}

.font-display-1 {
  font-size: var(--font-size-display-large);
  line-height: var(--line-height-display-large);
  font-weight: 500;
}
.font-display-2 {
  font-size: var(--font-size-display-medium);
  line-height: var(--line-height-display-medium);
  font-weight: 500;
}
.font-display-3 {
  font-size: var(--font-size-display-small);
  line-height: var(--line-height-display-small);
  font-weight: 400;
}

.font-headline-1 {
  font-size: var(--font-size-huge);
  line-height: var(--line-height-huge);
  font-weight: 600;
  color: var(--color-primary);
}
.font-headline-2 {
  font-size: var(--font-size-xxlarge);
  line-height: var(--line-height-xxlarge);
  font-weight: 500;
}
.font-headline-3 {
  font-size: var(--font-size-xlarge);
  line-height: var(--line-height-xlarge);
  font-weight: 500;
}
.font-headline-4 {
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-weight: 400;
}
.font-headline-5 {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  font-weight: 400;
}
.font-headline-6 {
  font-size: var(--font-size-bigger);
  line-height: var(--line-height-bigger);
  font-weight: 500;
}
.font-subhead-1 {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-narrow);
}
.font-subhead-2 {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-narrow);
}
.font-body-1 {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-wide);
}
.font-body-2 {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-medium);
}

.font-body-bigger {
  font-size: var(--font-size-bigger);
  line-height: var(--line-height-bigger);
  font-weight: 400;
}
.font-small {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  font-weight: 400;
}
.font-caption {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  font-weight: 400;
}

.font-overline {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-wide);
}
.font-label-small {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  font-weight: 500;
}
.font-label-large {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  font-weight: 500;
}

// IMPROVE Diseño
.font-statistics-xs {
  font-size: var(--font-size-display-medium);
  line-height: var(--line-height-display-medium);
  font-weight: 300;
}
