@mixin shadow-elevation-5($border-radius: var(--card-border-radius)) {
  position: relative;
  @include defaultTransition();

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    box-shadow: var(--shadow-elevation-5);
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    @include defaultTransition();
  }

  &:hover {
    &:before {
      box-shadow: var(--shadow-elevation-5-hover);
      transition: 0.3s;
    }
  }
}

@mixin shadow-elevation-4($border-radius: var(--card-border-radius)) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.03), 0px 2px 3px rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
  }
}

@mixin shadow-elevation-3($border-radius: var(--card-border-radius)) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.06);
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
  }
}

@mixin shadow-elevation-2($border-radius: var(--card-border-radius)) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
  }
}

@mixin shadow-elevation-1($border-radius: var(--card-border-radius)) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px 1px rgba(0, 0, 0, 0.03);
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
  }
}

@mixin line-clamp($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin defaultTransition {
  transition: all 0.3s ease-in-out;
}

@mixin defaultTransition {
  transition: all 0.3s ease-in-out;
}

@mixin skeleton-background {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 200%;
    left: 0;
    transform: translate(-100%);
    height: 100%;
    background: linear-gradient(90deg, #fff 0%, #ebf0f6 50%, #ffffff 100%);
    animation: skeleton-animation 2s linear infinite backwards;
  }
}

@mixin grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: min-content;
  column-gap: calc(var(--s) * 2);
  //IMPROVE use dawuti for breakpoint
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: calc(var(--s) * 3);
  }
}
