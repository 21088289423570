@use 'dawuti.config' as dawuti;
@include dawuti.breakpoints();
@include dawuti.wrapper-fluid();
@include dawuti.space-vars();

:root {
  --safe-area: calc(var(--s) * 2);
  --gutter: calc(var(--s) * 2);
  @include dawuti.mq-md {
    --safe-area: calc(var(--s) * 3);
  }

  body {
    min-height: 100%;
  }

  .rotateY180 {
    transform: scaleY(-1);
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  .uppercase {
    text-transform: uppercase;
  }

  // HELPERS

  .d-tablet {
    display: none;
    @include dawuti.mq-md {
      display: block;
    }
  }
  .d-mobile {
    display: block;
    @include dawuti.mq-md {
      display: none;
    }
  }

  //ACCESSIBLE CARDS
  //Apply in any clickable card
  a.accessible-card-element {
    //apply to the action/ readable element
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:focus {
      border: 0;
      outline: 0;
    }
    &:after {
      //will add a clickable overlay
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  .accessible-card {
    //add this class to the card
    &:focus-within {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

// GLOBAL STYLES

// Prevent material overlay from displacing page content and keep scroll blocking strategy.
html.cdk-global-scrollblock {
  position: static;
  overflow: hidden;
}

// TODO apply just in snackbars
// Applies in the snackbars and bottom sheets
// .cdk-overlay-container > .cdk-global-overlay-wrapper {
//   background: var(--color-primary-10-50);
// }

.no-border-radius {
  border-radius: 0 !important;
}
.no-shadow {
  box-shadow: none;
}
.no-padding {
  padding: 0 !important;
}
.full-width {
  width: 100vw;
  max-width: 100vw !important;
}

.page-width {
  width: calc(100vw - var(--toolbar-width));
  max-width: calc(100vw - var(--toolbar-width)) !important;
}
.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.wrapper-fluid--extra-narrow {
  max-width: 450px;
}

.wrapper-fluid--narrow {
  max-width: 640px;
}

.wrapper-fluid--medium {
  max-width: 854px;
}

.wrapper-fluid--wide {
  max-width: 1280px;
}

@include dawuti.reset-all();
