@use "@angular/material" as mat;
@use "dawuti.config" as dawuti;
@use "theme/mixins" as mixins;
@include mat.core();

$material-primary: (
  50: #e3ecf4,
  100: #b8cfe4,
  200: #89b0d2,
  300: #5990bf,
  400: #3678b2,
  500: #1260a4,
  600: #10589c,
  700: #0d4e92,
  800: #0a4489,
  900: #053378,
  A100: #a7c4ff,
  A200: #74a2ff,
  A400: #4181ff,
  A700: #2770ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$material-accent: (
  50: #ffefe5,
  100: #ffd7bd,
  200: #ffbd91,
  300: #ffa365,
  400: #ff8f44,
  500: #ff7b23,
  600: #ff731f,
  700: #ff681a,
  800: #ff5e15,
  900: #ff4b0c,
  A100: #ffffff,
  A200: #fff8f6,
  A400: #ffd0c3,
  A700: #ffbca9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($material-primary);
$my-accent: mat.define-palette($material-accent);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $material-primary,
      accent: $material-accent,
    ),
  )
);

@include mat.all-component-themes($my-theme);

// CHECKBOX
mat-checkbox.mat-primary.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: var(--color-text);
}

mat-checkbox.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--color-tertiary-40);
}

.mat-checkbox-label {
  white-space: break-spaces;
}
.mat-checkbox-layout {
  align-items: flex-start !important;
  // padding-top: calc(var(--s) * 1.5);
  .mat-checkbox-inner-container {
    margin-top: calc(var(--s) / 2);
    margin-bottom: calc(var(--s) / 2);
    margin-right: calc(var(--s) * 1.5);
  }
}

//PAGINATOR
.mat-paginator-container {
  justify-content: center !important;
  .mat-button-base {
    span {
      color: var(--color-text) !important;
    }
    &.mat-button-disabled {
      opacity: 0.3;
    }
  }
  .mat-paginator-page-size {
    display: none !important;
  }

  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      font-size: 14px;
      order: 1;
    }
    > button {
      &:first-child {
        order: 0;
      }
      &:last-child {
        order: 1;
      }
    }
  }
}

// SNACKBAR
.mat-snack-bar-container {
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

// RADIO BUTTONS
mat-radio-button .mat-radio-inner-circle {
  background-color: var(--color-text);
}
mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-text);
}
mat-radio-button .mat-radio-outer-circle {
  border-color: var(--color-neutral-variant);
}

// PROGRESS BAR
.mat-progress-bar {
  height: var(--s) !important;
}
.mat-progress-bar-background {
  fill: var(--color-neutral-variant-95);
}
.mat-progress-bar-buffer {
  background-color: var(--color-neutral-variant-95);
}
.mat-progress-bar-fill::after {
  background-color: var(--color-primary);
}

// FORM FIELDS
mat-form-field {
  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: var(--color-primary-95);
      border-radius: var(--form-field-border-radius);
      .mat-form-field-infix {
        top: -10px;
      }

      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-form-field-label {
      color: var(--color-neutral-variant-30);
    }
    .mat-form-field-wrapper {
      margin-bottom: 10px;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin-top: 0;
    }
    .mat-form-field-flex {
      border-radius: var(--form-field-border-radius);
      height: calc(var(--s) * 7.5);
      .mat-form-field-infix {
        top: -5px;
      }
      .mat-form-field-outline {
        border-radius: calc(var(--s) / 2);
        color: var(--color-neutral-variant-50);
      }
      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-form-field-label {
      color: var(--color-neutral-variant-30);
    }

    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        margin-bottom: var(--s);
      }
    }
    &:not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        padding-bottom: calc(var(--s) * 1.25);
      }
    }
  }
}

fieldset {
  border: 0;
  padding: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  // text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-hidden-wrapper {
  input {
    position: absolute;
    opacity: 0;
  }
}

.input {
  border: none;
  border-radius: var(--form-field-border-radius);
  border-bottom: 1px solid black;
  background-color: var(--color-primary-95);
  color: var(--color-text-variant);
  min-height: var(--input-height);
  &.input--input-hidden {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}

// DATEPICKER
.cdk-overlay-pane.mat-datepicker-popup {
  @include dawuti.mq-xs(down) {
    width: 100%;
    right: 0 !important;
    left: 0 !important;
  }
}

.mat-datepicker-content {
  background-color: var(--color-primary-90);
  padding: calc(var(--s) * 2);
  box-shadow: none;
  border-radius: var(--card-border-radius) !important;

  width: 375px;
  @include mixins.shadow-elevation-5;

  .mat-calendar {
    width: 100% !important;
    height: auto !important;
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-body-label {
    opacity: 0;
    padding: 0 !important;
  }

  .mat-calendar-table-header {
    th {
      letter-spacing: 0.1px;
      opacity: 0.4;
      font-weight: 500;
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
  }

  .mat-calendar-body-cell {
    .mat-calendar-body-selected {
      background-color: var(--color-primary);
      color: var(--color-light);
    }
    .mat-calendar-body-selected.mat-calendar-body-today {
      background-color: var(--color-primary);
      color: var(--color-light);
    }

    &.is-highlighted {
      .mat-calendar-body-cell-content {
        background-color: var(--color-primary-90);
        color: var(--color-primary);
      }
      .mat-calendar-body-cell-content.mat-calendar-body-selected {
        background-color: var(--color-primary);
        color: var(--color-light);
      }
    }
  }

  .mat-calendar-body-cell {
    &.is-highlighted {
      .mat-calendar-body-cell-content {
        background-color: var(--color-light);
        color: var(--color-primary);
        &.mat-calendar-body-today {
          border: 2px solid var(--color-primary);
        }
        &.mat-calendar-body-today.mat-calendar-body-selected {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .mat-calendar-body-cell-content {
    border: none;
    font-weight: 500;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);

    &.mat-calendar-body-today {
      background-color: var(--color-primary);
      color: var(--color-light);
    }
  }
}
