@use 'sass:math';
@use 'dawuti.config' as dawuti;

$html-font-size: 16px;
@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}
@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

:root {
  --font-primary: 'Roboto';
  --html-font-size: #{$html-font-size};

  --font-size-xxsmall: #{rem(11px)};
  --line-height-xxsmall: #{rem(16px)};

  --font-size-xsmall: #{rem(12px)};
  --line-height-xsmall: #{rem(16px)};

  --font-size-small: #{rem(14px)};
  --line-height-small: #{rem(20px)};

  --font-size-base: #{rem(16px)};
  --line-height-base: #{rem(24px)};

  --font-size-bigger: #{rem(18px)};
  --line-height-bigger: #{rem(24px)};

  --font-size-medium: #{rem(22px)};
  --line-height-medium: #{rem(28px)};

  --font-size-large: #{rem(24px)};
  --line-height-large: #{rem(32px)};

  --font-size-xlarge: #{rem(28px)};
  --line-height-xlarge: #{rem(36px)};

  --font-size-xxlarge: #{rem(32px)};
  --line-height-xxlarge: #{rem(40px)};

  --font-size-huge: #{rem(36px)};
  --line-height-huge: #{rem(44px)};

  --font-size-display-large: #{rem(64px)};
  --line-height-display-large: #{rem(74px)};

  --font-size-display-medium: #{rem(57px)};
  --line-height-display-medium: #{rem(64px)};

  --font-size-display-small: #{rem(45px)};
  --line-height-display-small: #{rem(52px)};

  --profile-heading-font-size: #{rem(32px)};
  --profile-heading-line-height: #{rem(40px)};

  --color-light: #fff;

  --color-primary: #1260a4;
  --color-primary-10: #001c39;
  --color-primary-20: #00315c;
  --color-primary-30: #004883;
  --color-primary-70: #72aef7;
  --color-primary-80: #9fc9ff;
  --color-primary-90: #d2e4ff;
  --color-primary-90: #d2e4ff;
  --color-primary-95: #e9f1ff;
  --color-primary-99: #fcfdff;

  --color-primary-10-00: #001c39;
  --color-primary-10-08: #001c3914;
  --color-primary-10-50: #001c3950;
  --color-primary-40-16: #1260a429;

  --color-secondary: var(--color-secondary-60);
  --color-secondary-10: #341100;
  --color-secondary-20: #552100;
  --color-secondary-30: #783100;
  --color-secondary-40: #9e4300;
  --color-secondary-50: #c55500;
  --color-secondary-60: #ea6c10;
  --color-secondary-70: #ff8d47;
  --color-secondary-80: #ffb68d;
  --color-secondary-90: #ffdbc8;
  --color-secondary-95: #ffede4;
  --color-secondary-99: #fffbf9;

  --color-tertiary: #199139;
  --color-tertiary-30: #005317;
  --color-tertiary-40: #006e22;
  --color-tertiary-40-15: #006e2226;
  --color-tertiary-40-10: #006e22;
  --color-tertiary-70: #53c162;
  --color-tertiary-80: #6fdd7a;
  --color-tertiary-90: #8bfb94;
  --color-tertiary-95: #d9ffd9;
  --color-tertiary-99: #f4ffef;

  --color-custom-tertiary-85: #ceffa0;
  --color-custom-purple-20: #e5deff;
  --color-custom-secondary-20: #ffddaf;
  --color-custom-blue-20: #b1fff3;

  // IMPROVE colors are wrong labeled
  --color-neutral: #635e56;
  --color-neutral-variant-85: #969088;
  --color-neutral-90: #e8e1d8;
  --color-neutral-95: #f8f0e7;
  --color-neutral-99: #fffbf8;
  --color-neutral-40: #a5a4a2;
  --color-neutral-10: #1e1b16;

  --color-neutral-variant: #5a5e66;
  --color-neutral-variant-30: #43474e;
  --color-neutral-variant-50: #73777f;
  --color-neutral-variant-60: #8d9199;
  --color-neutral-variant-80: #c3c6cf;
  --color-neutral-variant-90: #dfe2eb;
  --color-neutral-variant-95: #edf0f9;
  --color-neutral-variant-99: #fcfdff;
  // CUSTOM COLORS (AVATARS)
  --color-custom-yellow: #ffb948;
  --color-custom-yellow-secondary: #fff6d2;
  --color-custom-yellow-contrast: #633f00;

  --color-custom-orange: #ff8b69;
  --color-custom-orange-contrast: #633f00;

  --color-custom-pink: #f77fea;
  --color-custom-pink-contrast: #633f00;

  --color-custom-purple: #aba0ff;
  --color-custom-purple-contrast: #633f00;

  --color-custom-dark-purple: #5741e2;

  --color-custom-blue: #8bcdff;
  --color-custom-blue-contrast: #633f00;

  --color-custom-green: #87dc44;
  --color-custom-green-contrast: #633f00;

  --color-custom-turquoise: #00dec9;
  --color-custom-turquoise-contrast: #633f00;

  --color-custom-carer-green: #6fdd7a;
  --color-custom-carer-green-contrast: #633f00;

  --color-carer-green: var(--color-tertiary-80);
  --color-carer-green-contrast: var(--color-tertiary-30);

  --color-success: #199139;
  --color-success-60: #53c162;
  --color-success-40-16: #d6e8dc;
  --color-warning: #f7bf31;
  --color-error: #ba1b1b;
  --color-error-50: #dd3730;
  --color-error-60: #ff5449;
  --color-error-80: #ffb4a9;
  --color-error-90: #ffdad4;
  --color-error-99: #ffede9;

  --color-text: #33302a;
  --color-text-variant: #43474e;
  --color-text-light: #848381;

  --color-button-hover: #256dab;
  --color-button-focus: #2e73af;

  --color-button-outline-hover: #edf1fb;
  --color-button-outline-focus: #e4eafa;

  --color-button-secondary-hover: #ecccbc;
  --color-button-secondary-focus: #e3c4b5;

  --color-button-tertiary-hover: #61c66f;
  --color-button-tertiary-focus: #68c875;

  --color-chip-enabled-hover: #f0f0f1;
  --color-chip-enabled-focus: #e9e9ea;

  --color-chip-selected-hover: #ecccbc;
  --color-chip-selected-focus: #e3c4b5;

  --color-on-background: rgba(30, 27, 22, 0.6);

  --shadow-elevation-5: 2px 10px 30px 10px rgba(0, 0, 0, 0.05),
    2px 10px 20px rgba(0, 0, 0, 0.03);
  --shadow-elevation-5-hover: 2px 10px 30px 10px rgba(0, 0, 0, 0.1),
    2px 10px 20px rgba(0, 0, 0, 0.06);
  --shadow-elevation-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.03),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  --shadow-elevation-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.03),
    0px 1px 3px rgba(0, 0, 0, 0.06);
  --shadow-elevation-2: 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  --shadow-elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.05),
    0px 1px 3px 1px rgba(0, 0, 0, 0.03);

  --header-height: 74px;

  @include dawuti.mq-md {
    --header-height: 80px;
  }

  --toolbar-top-height: 64px;
  @include dawuti.mq-md {
    --toolbar-top-height: 100px;
  }

  --toolbar-height: 88px;
  --toolbar-width: 0px;
  @include dawuti.mq-md {
    --toolbar-height: 0px;
    --toolbar-width: 240px;
  }
  @include dawuti.mq-xl {
    --toolbar-width: 358px;
  }
  --toolbar-icon-color: var(--color-secondary-10);
  --toolbar-links-color: var(--color-neutral-variant-50);
  --toolbar-links-color-active: var(--color-secondary-10);
  --toolbar-icons-color: var(--color-secondary-10);

  --form-field-border-radius: 4px 4px 0 0;

  --calendar-header-height: calc(
    var(--toolbar-top-height) + 252px + var(--ion-safe-area-top, 0)
  );
  --calendar-header-height-collapsed: calc(
    var(--s) * 16 + var(--ion-safe-area-top, 0)
  );
  @include dawuti.mq-md {
    --calendar-header-height: calc(168px + var(--ion-safe-area-top, 0));
  }
  --letter-spacing-wide: 0.5px;
  --letter-spacing-medium: 0.25px;
  --letter-spacing-narrow: 0.1px;

  --page-padding-top: calc(var(--s) * 4);
  --page-padding-bottom: calc(var(--s) * 7);

  //Elements
  --card-border-radius: calc(var(--s) * 1.5);
  --card-border-radius-small: var(--s);
  --card-border: 1px solid var(--color-neutral-variant-90);
  --card-border-red: 1px solid var(--color-error);

  //Element colors
  --header-welcome-color: var(--color-primary);
  --section-formative-areas-bg-color: var(--color-neutral-95);
  --profile-title-color: var(--color-text);
  --toolbar-bg-color: var(--color-neutral-99);
  --consult-tab-bg-color: var(--color-secondary-90);
  .role-carer {
    --color-secondary: #199139;
    --color-secondary-10: #07200b;
    --color-secondary-30: #005317;
    --color-secondary-40: #006e22;
    --color-secondary-80: #6fdd7a;
    --color-secondary-90: #8bfb94;
    --color-secondary-99: #f4ffef;

    --color-orange: var(--color-orange-60);
    --color-orange-10: #341100;
    --color-orange-20: #552100;
    --color-orange-30: #783100;
    --color-orange-40: #9e4300;
    --color-orange-50: #c55500;
    --color-orange-60: #ea6c10;
    --color-orange-70: #ff8d47;
    --color-orange-80: #ffb68d;
    --color-orange-90: #ffdbc8;
    --color-orange-95: #ffede4;
    --color-orange-99: #fffbf9;

    //Element colors
    --header-welcome-color: var(--color-secondary-40);
    --section-formative-areas-bg-color: var(--color-secondary-99);
    --profile-title-color: var(--color-secondary-40);
    --toolbar-bg-color: var(--color-secondary-99);
    --consult-tab-bg-color: var(--color-neutral-99);
  }

  // Forms
  --input-height: 60px;
}
